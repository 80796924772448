<template>
  <v-container style="height: 400px" v-if="loading">
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col class="text-subtitle-1 text-center" cols="12">
        Buscando informações
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "progress-bar",
  data: () => ({
    frases: [
      { text: "Carregando arquivos", description: "" },
      { text: "Enviando informações", description: "" },
      { text: "Buscando por pesquisa", description: "" },
      { text: "Validando informações", description: "" },
      { text: "Configurando formulário", description: "" },
      { text: "Buscando perguntas", description: "" },
      { text: "Mais um momento", description: "" },
    ],
  }),
  computed: {
    loading: {
      get() {
        return this.$store.getters["pesquisas/getLoadingPesquisaExterna"];
      },
    },
  },
};
</script>

<style>
</style>